import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';
import './registration.css';

function ResetP() {

    const [showEmailForm, setShowEmailForm] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    // Handle form submission/validation
    const handleFormSubmit = () => {
        let isValid = true;

        // Email validation
        if (!email) {
            setEmailError('Please enter a valid email address');
            isValid = false;
        } else {
            setEmailError('');
        }

        // If valid, hide the form and show the reset_password_table message
        if (isValid) {
            setShowEmailForm(true); // Shows the reset_password_table and Next button
            console.log('Form is valid, proceed with submission');
        }
    };

    return(
        <div className='reset_password'>
            <div className='container'>
                <div className='reset_password_content'>
                    <div className='reset_password_textwrapper'>
                        <span className='reset_password_textbig'>
                            Forgot password
                        </span>
                        <span className='reset_password_text'>
                            Enter the email address for your account so we can send you reset instructions
                        </span>
                    </div>

                    {!showEmailForm ? (
                        <div className='vanishing_wrapper'>
                            <div className='input_container'>
                                <input 
                                    type="email" 
                                    className={`input_field ${emailError ? 'input_error' : ''}`} 
                                    placeholder="Email" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {emailError && <span className="error_message">{emailError}</span>}
                            </div>
                            <div className='reset_password_wrapper'>
                                <Link className='reset_password_returnlink'to="/registrationin">
                                    Return to Sign in
                                </Link>
                                <div className='email_button_wrapper'> 
                                    <button className='email_button' onClick={handleFormSubmit}>
                                        Email me reset instruction
                                    </button>
                                </div>
                                <span className='reset_password_grouptext'>
                                    If you don’t see your reset email, be sure to check your spam filter
                                </span>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <span className='reset_password_table'>
                                Thank you. If an account exists with this email address, you should receive an email to reset your password.
                            </span>
                            <div className='next_button_wrapper'>
                                <button className='next_button'>
                                    Next
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ResetP;
