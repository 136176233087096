import React, { useState } from 'react'; 
import { Link } from 'react-router-dom';
import './registration.css';
import Google from './../../img/Googleicon.svg';
import Email from './../../img/emailicon.svg';
import Samurai from './../../img/samurai.jpg';

function SignIn() {
    const [showEmailForm, setShowEmailForm] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [rememberMe, setRememberMe] = useState(false);

    // Handle email button click
    const handleEmailClick = () => {
        setShowEmailForm(true);
    };

    // Validate password
    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{7,}$/;
        return passwordRegex.test(password);
    };

    // Handle form submission/validation
    const handleFormSubmit = () => {
        let isValid = true;
        if (!email) {
            setEmailError('Please enter a valid email address');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!password) {
            setPasswordError('This field is required');
            isValid = false;
        } else if (!validatePassword(password)) {
            setPasswordError('Must be at least 7 characters long with letters and numbers');
            isValid = false;
        } else {
            setPasswordError('');
        }

        // If valid, proceed (you can add more logic here, such as form submission)
        if (isValid) {
            console.log('Form is valid, proceed with submission');
        }
    };

    return (
        <div className='registration'>
            <div className='container'>
                <div className='registration_content'>
                    <div className='registration_left'>
                        <span className='left_text'>
                            Sign in
                        </span>
                        <div className='registration_left_link'>
                            {!showEmailForm ? (
                                <div className='registration_left_link'>
                                    <div className='registration_wrapper_link'>
                                        <img className='link_img' src={Google} alt="Google icon" />
                                        <Link className='google_link'>
                                            Sign up with Google
                                        </Link>
                                    </div>
                                    <div className='wrapper_or_border'>
                                        <div className='registration_or'></div>
                                            <span className='choice_text'>
                                                or
                                            </span>
                                        <div className='registration_or'></div>
                                    </div>
                                    <div className='registration_wrapper_link' onClick={handleEmailClick}>
                                        <img className='link_img' src={Email} alt="Email icon" />
                                        <Link className='email_link'>
                                            Email
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div className='email_form'>
                                    <div className='input_container'>
                                        <input 
                                            type="email" 
                                            className={`input_field ${emailError ? 'input_error' : ''}`} 
                                            placeholder="Email" 
                                            value={email} 
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {emailError && <span className="error_message">{emailError}</span>}
                                    </div>
                                    <div className='input_container'>
                                        <input 
                                            type="password" 
                                            className={`input_field ${passwordError ? 'input_error' : ''}`} 
                                            placeholder="Password" 
                                            value={password} 
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {passwordError && <span className="error_message">{passwordError}</span>}
                                        <Link className='forgot_yourpassword_text' to="/registpassword">
                                                I forgot password or can't sign in
                                        </Link>
                                    </div>

                                    {/* Conditionally render both "I forgot password" and "Remember me" */}
                                    {!emailError && !passwordError && (
                                        <>
                                        <div className='wrapper_signin'>
                                            <div className='remember_me_container'>
                                                <input className='remember_me_input' 
                                                    type="checkbox" 
                                                    checked={rememberMe} 
                                                    onChange={(e) => setRememberMe(e.target.checked)}
                                                />
                                                <label className='remember_me_label'>
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                        </>
                                    )}

                                    <div className='email_button_wrapper'> 
                                        <button className='email_button' onClick={handleFormSubmit}>
                                            Sign in
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className='registration_left_border'></div>
                        <div className='registration_left_footer'>
                            <span className='registration_footer_text'>
                                Do not have an account? 
                            </span>
                            <Link className='registration_footer_text registration_footer_link' to="/registrationup">
                                Sign up
                            </Link>
                        </div>
                    </div>
                    <div className='registration_right'>
                        <img className='img_right' src={Samurai} alt="Samurai" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignIn;
