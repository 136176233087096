import './components.css'
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="footer_content">
                    <div className="footer_left">
                        <Link to="/terms" className="footer_link">
                        Terms
                        </Link>
                        <Link to="/policy" className="footer_link">
                            Privacy
                        </Link>
                    </div>
                    <a className="footer_link footer_copyright">
                        2024 MarketVisor.online. All Rights Reserved
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;