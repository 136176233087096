

import React, { useState, useEffect } from 'react';
import './components.css';

function Test() {
    const [markets, setMarkets] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMarketData = async () => {
            try {
                const response = await fetch('http://localhost:4000/api/markets/ethereum');
                if (!response.ok) {
                    throw new Error('Failed to fetch market data');
                }
                const data = await response.json();
                setMarkets(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchMarketData();
    }, []);

    if (loading) {
        return <div>Loading market data...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className='tests'>
            <h1>Market Prices for Ethereum</h1> {/* You can dynamically change this if needed */}
            {markets.length > 0 ? (
                <ul>
                    {markets.map((market, index) => (
                        <li key={index}>
                            <h3>{market.name}</h3>
                            <p>Price: ${market.price}</p>
                            <p>24h Volume: {market.volume_24h}</p>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No market data available.</p>
            )}
        </div>
    );
}

export default Test;
