import './App.css';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import MainPage from './pages/MainPage/MainPage';
import Policy from './pages/Privacy/Policy';
import Cookies from './pages/Privacy/Cookies';
import Terms from './pages/Privacy/Terms';
import Footer from './components/Footer';
import Test from './components/Test';
import SignUp from './pages/Registration/SignUp';
import SignIn from './pages/Registration/SignIn';
import ResetP from './pages/Registration/ResetP';

function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/cookies" element={<Cookies />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/public" element={<Test />} />
        <Route path="/registrationup" element={<SignUp />} />
        <Route path="/registrationin" element={<SignIn />} />
        <Route path="/registpassword" element={<ResetP />} />
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
